import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import BlogPostCard from './BlogPostCard';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const LatestArticles = () => {
  const data = useStaticQuery(graphql`
    query LatestArticlesQuery {
      allMarkdownRemark(
        limit: 4
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              templateKey
              title
              description
              date(formatString: "DD MMMM YYYY", locale: "bg")
              featuredpost
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 640, maxHeight: 350, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt(format: PLAIN, pruneLength: 200)
          }
        }
      }
    }
  `);

  const { edges: posts } = data.allMarkdownRemark;

  return (
    <section className="section">
      <h1 class="is-size-1" style={{ marginBottom: '1rem' }}>
        Последни статии
      </h1>
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => {
            const className = `blog-list-item tile is-child ${
              post.frontmatter.featuredpost ? 'is-featured' : ''
            }`;

            const featuredImage = post.frontmatter.featuredimage ? (
              <div className="featured-thumbnail">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.frontmatter.featuredimage,
                    alt: `featured image thumbnail for post ${post.title}`,
                  }}
                />
              </div>
            ) : null;

            return (
              <div className="is-parent column is-6" key={post.id}>
                <BlogPostCard
                  className={className}
                  featuredImage={featuredImage}
                  link={post.fields.slug}
                  title={post.frontmatter.title}
                  date={post.frontmatter.date}
                  excerpt={post.excerpt}
                />
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default LatestArticles;
